import React from 'react'

import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'
import { useI18n, I18nManager, I18nContext, TranslationDictionary } from '@shopify/react-i18n'
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom'

import '@shopify/polaris/build/esm/styles.css'

import './css/faslet.css'
import './css/animations.css'
import { FasletApp } from './FasletApp'

function I18nWrapper() {
  const [searchParams] = useSearchParams()
  const locale = searchParams.get('locale') ?? undefined

  const i18nManager = new I18nManager({
    locale: locale ?? 'en',
    fallbackLocale: 'en',
    onError(error) {
      console.error(error)
    },
  })

  return (
    <I18nContext.Provider value={i18nManager}>
      <AppWrapper />
    </I18nContext.Provider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <I18nWrapper />
    </BrowserRouter>
  )
}

function AppWrapper() {
  const [i18n] = useI18n({
    id: 'Polaris',
    fallback: translations,
    translations(locale: string) {
      // regex for locale to not match english, german, or dutch
      if (!locale.match(/^en/) && !locale.match(/^de/) && !locale.match(/^nl/)) {
        return translations
      }
      return import(
        /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${locale}.json`
      ).then(dictionary => dictionary && dictionary.default)
    },
  })

  return (
    <AppProvider i18n={i18n.translations}>
      <Routes>
        <Route path="*" element={<FasletApp />} />
      </Routes>
    </AppProvider>
  )
}

export default App
