import { useState } from 'react'
import { Button, Link, Text } from '@shopify/polaris'

import { Gap, ImagePopup, Row, Spacer } from '../../components'
import { PricePlan, Status } from '../../types'
import en from '../../translations/en.json'
import de from '../../translations/de.json'
import nl from '../../translations/nl.json'
import { useI18n } from '@shopify/react-i18n'

interface AppBlockInstallationProps {
  shop?: string
  shopId?: string
  status: Status
  pricePlan: PricePlan
}

export function AppBlockInstallation({ shop, shopId, status, pricePlan }: AppBlockInstallationProps) {
  const [popup, showPopup] = useState(false)

  const [i18n] = useI18n({
    id: 'home',
    translations(locale) {
      console.log('got locale', locale)
      if (locale.startsWith('de')) {
        return de
      }
      if (locale.startsWith('nl')) {
        return nl
      }
      return en
    },
  })

  return (
    <>
      {!!shopId && !!pricePlan.name && (
        <Row>
          <Text variant="bodyMd" as="h4">
            {i18n.translate('appBlock.fasletButton')}
          </Text>
          <Spacer />
          <Button onClick={() => showPopup(true)}>{i18n.translate('appBlock.howToInstall')}</Button>
        </Row>
      )}
      <ImagePopup
        image={'https://widget.prod.faslet.net/misc/shopify-app-block.gif'}
        alt={i18n.translate('appBlock.animationAltText')}
        title={i18n.translate('appBlock.howToAdd')}
        open={popup}
        onClose={() => showPopup(false)}
        info={
          <>
            <Text variant="headingMd" as="h4">
              {i18n.translate('appBlock.popup.howToTitle')}
            </Text>
            <ul>
              <li>
                {i18n.translate('appBlock.popup.themeEditorDesc', {
                  themeEditorLink: (
                    <Link url={`https://${shop}/admin/themes/${status.activeThemeId}/editor`} target="_blank">
                      {i18n.translate('appBlock.popup.themeEditor')}
                    </Link>
                  ),
                })}
              </li>
              <li>{i18n.translate('appBlock.popup.addButton')}</li>
              <li>{i18n.translate('appBlock.popup.positionButton')}</li>
              <li>{i18n.translate('appBlock.popup.saveChanges')}</li>
            </ul>
            <Gap />
          </>
        }
      ></ImagePopup>
    </>
  )
}
